<template>
  <div class="payment-details">
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-between">
        <b>{{
          $t("name-payment-details", {
            name: getUsernameById(preferences.user_id)
          })
        }}</b
        >{{ preferences.payment_method && preferences.payment_method.name }}
      </b-list-group-item>

      <!-- Paypal -->
      <b-list-group-item
        class="d-flex justify-content-between"
        v-for="(v, k) in fields"
        :key="k"
      >
        <span>
          {{ $t(kebab(k)) }}
        </span>
        <span>{{ v }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { kebab } from "case";
import { find } from "lodash-es";

export default {
  name: "payment-details",
  props: {
    preferences: Object,
    users: Array
  },
  computed: {
    fields() {
      const { preferences } = this.$props;
      return {
        ...preferences.payment_method_details,
        minimum_payment_balance:
          preferences.minimum_payment_balance &&
          this.currencySymbol(preferences),
        payment_frequency:
          preferences.payment_frequency && preferences.payment_frequency.name,
        currency: preferences.currency && preferences.currency.code
      };
    }
  },
  methods: {
    kebab,
    getUsernameById(id) {
      try {
        const user = find(this.users, o => o.id === id);
        return user.name;
      } catch (e) {
        return this.$t("user-not-found");
      }
    },
    currencySymbol(preferences) {
      if (preferences.currency) {
        return this.formatCurrency(
          preferences.minimum_payment_balance.balance,
          preferences.currency.code
        );
      }

      return this.formatNumber(preferences.minimum_payment_balance.balance);
    }
  }
};
</script>

<style></style>
